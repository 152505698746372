
import './assets/developer/developer.css';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export default function Developer() {

  const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
    <span {...props}>{children}</span>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: (<SlickButtonFix>
      <button type='button'
        // className='slick-prev slick-arrow'
        style={{border: "none", cursor: "pointer", marginLeft: "-30px"}}
        ><LeftOutlined style={{cursor: "pointer"}}/></button>
    </SlickButtonFix>),
    nextArrow: (<SlickButtonFix>
      <button type='button'
        // className='slick-next slick-arrow'
        style={{border: "none", cursor: "pointer"}}
        ><RightOutlined style={{cursor: "pointer"}}/></button>
    </SlickButtonFix>),
  };
  return (
    <>
    <div className="developerHead">
      {/* <div className="introWrap">
        <h1 style={{color: "#FFFFFF"}}>智慧安全用电解决方案</h1>
        <div className="introContent">
          <p>智慧安全用电是针对当前电气火灾事故频发而创新的一套电气火灾预警和预防管理系统， 近年来随着时间推移，线路老化，用电设备增多，功率增大出现电压过载，漏电等问题造成火灾隐患。 智慧安全用电系统就是利用边缘侧的智能传感器终端设备将引起电气火灾的主要因素汇总实时上报给智慧安全用电系统， 通过系统监控、异常数据告警，工单派发等功能及时处理用电异常，防患火灾事故。</p>
        </div>
      </div> */}
    </div>
    <div className="developerContent">
      <div style={{width: "60vw"}}>
        <Slider {...settings}>
          <div className="slickCard">
            <div>
              <div>
                <h2 className="platformIntro">平台介绍</h2>
                <div className="items">
                  <p>发展战略</p>
                  <p>平台架构</p>
                  <p>快速入门</p>
                </div>
              </div>
            </div>
          </div>
          <div className="slickCard">
            <div>
              <div>
                <h2 className="protocol">通讯协议</h2>
                <div className="items">
                  <p>MQTT</p>
                  <p>LWM2N</p>
                  <p>OPC UA</p>
                </div>
              </div>
            </div>
          </div>
          <div className="slickCard">
            <div>
              <div>
                <h2 className="netSafe">网络安全</h2>
                <div className="items">
                  <p>SSL/TSL</p>
                  <p>身份认证&权限认证</p>
                </div>
              </div>
            </div>
          </div>
          <div className="slickCard">
            <div>
              <div>
                <a href="http://10.10.101.204:9002/testcase/#/index" target="_blank" rel="noopener noreferrer">
                  <h2 className="test">测试用例/机理模型</h2>
                  <div className="items">
                    <p>工业大数据存储分析测试用例</p>
                    <p>工业数据智能测试用例</p>
                    <p>边缘计算分析测试用例</p>
                    <p>工控系统测试用例</p>
                    <p>5G网络切片应用测试用例</p>
                    <p>区块链技术应用测试用例</p>
                    <p>分布式新能源数字孪生测试用例</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="slickCard">
            <div>
              <div>
                <h2 className="func">功能模块</h2>
                <div className="items">
                  <p>设备接入</p>
                  <p>设备管理</p>
                  <p>边缘计算</p>
                  <p>数据湖</p>
                  <p>数据可视化</p>
                </div>
              </div>
            </div>
          </div>
          <div className="slickCard">
            <div>
              <div>
                <h2 className="APIdev">API开发指南</h2>
                <div className="items">
                  <p>基础服务API</p>
                </div>
              </div>
            </div>
          </div>
          <div className="slickCard">
            <div>
              <div>
                <h2 className="download">下载中心</h2>
                <div className="items">
                  <p>文档下载</p>
                  <p>SDK下载</p>
                  <p>云平台根证下载</p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
    </>
  )
}
