import { Button } from 'antd'
import './assets/multiPark/multiPark.css'

export default function MultiPark() {
  const feature = require('./assets/multiPark/feature.svg').default;

  const LinkToo = (url) => {
    window.open(url);
  };

  return (
    <div className="multiBody">
      <div className="multiHeader">
        <div className="headerWrap">
          <div className="headerContent">
            <h1>多能互补园区</h1>
            <p>围绕电气与能源领域，从规划和建设的整个过程中秉承了创新，协调，绿色，开放，共享的指导理论和目标， 摒弃能源系统（冷，热，电，气）规划和建设中的各自为战的格局中，改变 “重供给，轻需求” 的思路，力争做到各类能源相互协调，能源供需相互协调。</p>
          </div>
          <div className="link">
            <Button onClick={() => LinkToo('http://xrclfanb.cloudpreview.cloud.techsel.net/')}>海宁多能</Button>
            <Button onClick={() => LinkToo('https://txhq.crplingxi.com.cn/market/page/index/tx_login.html?ReturnUrl=/api/market/index/customer.jhtml')}>泰兴多能</Button>
            <Button onClick={() => LinkToo('https://xclkjy.crplingxi.com.cn/market/page/index/jb_login.html?ReturnUrl=/api/market/index/customer.jhtml')}>江北多能</Button>
          </div>
        </div>
      </div>
      <div className="multiIntro">
        <h2>方案介绍</h2>
        <div className="Wrap">
          <div className="introWrap">
            <p>随着光伏、储能等新能源成本不断降低，商业园区、楼宇对光伏、储能、柴油发电机组等多能源互补应用越来越多，源-网-荷-储协调互动的用户侧微网多能互补系统既可以降低用户用电成本，又可以提供供电可靠性，同时对电力用户侧的负荷管理也会起到很好的作用，将大大促进用户侧微网的市场发展。多能互补智慧园区的建设有利于提高能源供需协调能力，推动能源清洁生产和就近消纳，促进可再生能源消纳，是提高能源系统综合效率的重要抓手，对于建设清洁低碳、安全高效现代能源体系具有重要的现实意义和深远的战略意义。</p>
          </div>
        </div>
      </div>
      <div className="multiFeature">
        <div className="fWrap">
          <div className="featureWrap">
            <h2>方案特点</h2>
            <p>多能互补智慧园区是满足终端用户提供电、热、冷、气等多种用能需求，因地制宜、统筹开发、互补利用传统能源和新能源，优化布局建设一体化集成供能基础设施，通过分布式光伏系统、储能系统、天然气热电冷三联供、电动汽车充放电系统等多种分布式可新能源，结合智能微网系统架构方式，实现多能协同供应和能源综合梯级利用，并实现在多电力交易主体互动框架下，因不同的主体利益诉求不同，其参与互动的目标也有所差异，建立多种能源管理互动策略与交易机制，满足各个主体不同的利益目标。</p>
          </div>
          <img src={feature} alt="方案特点" style={{width: "550px", marginBottom: "-100px"}}></img>
        </div>
      </div>
    </div>
  )
}
