import React, {useState} from 'react';
import styled from 'styled-components/macro';
import { Layout, Menu } from 'antd';
import './assets/data/index.css';

const footerbg = require('./assets/data/footerbg.svg').default;
const introCbg = require('./assets/data/introCbg.svg').default;
const featureCbg = require('./assets/data/featureCbg.svg').default;

export default function DataHome() {

  const { Footer, Content } = Layout;

  const introduction = [
    {title: '数据集成', content: '复杂网络环境和协议、多样异构数据源之间的数据传输与上云。'},
    {title: '数据开发', content: '在线批处理、流处理和机器学习等多引擎任务开发，构建复杂的调度依赖，提供开发、生产环境隔离的开发模式。'},
    {title: '数据分析', content: '提供灵活的即时查询，输入SQL，可以即时查看图表。基于多维表格的数据透视、聚合与展示，进行快速数据分析和报表生成。'},
    {title: '数据服务', content: '零代码快速生成数据服务API。'},
    {title: '智能监控', content: '一键实现复杂工作流的全链路监控报警配置。'},
    {title: '数据地图', content: '提供强大的数据搜索、数据类目、数据血缘等能力。'},
    {title: '数据资产', content: '统一管理数据表、API等各类数据资产。'},
    {title: '数据标准', content: '提供便捷的数据标准定义，数据标准内容，引用管理，通过关联数据资产和质量，安全，组织，流程管控，提升数据质量。'},
    {title: '数据质量', content: '通过表级别、字段级别监控规则定义，及时感知脏数据。'},
    {title: '数据安全', content: '数据审计、数据脱敏、权限控制等能力。'}
  ]

  const [clickTitle, setClickTitle] = useState(introduction[0].title);

  const footerContent = '支持海量数据的离线加工分析，完成数据的汇聚集成、开发、生产调度与运维、离线与实时分析、数据质量治理与资产管理、安全审计、数据共享与服务、机器学习、应用搭建等覆盖大数据全生命周期的最佳实践。'

  const ClickNav = (e) => {
    setClickTitle(e.key);
  }

  return (
    <Layout style={{width:'100%', height: '100%', background: '#FFFFFF'}}>
      <Content>
        <div className="introW">
          <div className="introWrap">
            <div className="intro" style={{display: 'inline-block', width: '420px'}}>
              <div className="introData">
                <h1 className="data">数据</h1>
                <div className="dataR">
                  <h4>从采集——展现工具化</h4><h4>分析——驱动应用工具化</h4>
                </div>
              </div>
              <p className="fea">一站式解决</p>
              <p className="fea">真正实现<strong style={{color: '#333333'}}>数据业务化、业务数据化</strong></p>
            </div>
            <IntroContent/>
          </div>
        </div>
        <div className="feature">
          <div className="featureTitle">产品特色</div>
          <div className='temp'>
            <div className='card'>
              <Menu
                className='feaMenu'
                mode='vertical-left'
                onClick={ClickNav}
                defaultSelectedKeys={[introduction[0].title]}
                >
                  {introduction.map(item => (
                    <Menu.Item key={item.title}>{item.title}</Menu.Item>
                  ))}
              </Menu>
            </div>
            <div className='card' style={{width: '60vw', marginLeft: '20px'}}>
              <span style={{display: 'inline-flex', width: '100%', justifyContent: 'center'}}>
                {introduction.map(item => {
                  if(item.title === clickTitle) {
                    return (
                      <div className='cardWrap' key={item.title}>
                        <div className='cardContent'>
                          <h3><strong>{item.title}</strong></h3>
                          <p>{item.content}</p>
                        </div>
                        <div className='cardImg'>
                          <FeatureContent/>
                        </div>
                      </div>
                      )
                  } else {
                    return <></>
                  }
                })}
              </span>
            </div>
          </div>
        </div>
        
      </Content>
      <Footer style={{padding: 0}}>
        <FooterBg>
          <div className="fooCenter">{footerContent}</div>
        </FooterBg>
      </Footer>
    </Layout>
  )
}

const IntroContent = styled.div`
  background-image: url(${introCbg});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  width: 16vw;
  height: 35vh;
  display: inline-block;
  margin-left: 200px;
`;

const FeatureContent = styled.div`
  background-image: url(${featureCbg});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  width: 20vw;
  height: 40vh;
  display: inline-block;
`;

const FooterBg = styled.div`
  background-image: url(${footerbg});
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 7.4vw;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
`;
