import React from 'react';
import styled from 'styled-components/macro';

const header = require('./assets/biz/header.svg').default;
const body = require('./assets/biz/body.svg').default;

export default function BizHome() {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%'}}>
      <ImgChange></ImgChange>
      <div style={{textAlign: 'center'}}>
        <ImgAdapt src={body}></ImgAdapt>
      </div>
    </div>
  )
}
const ImgAdapt = styled.img`
  margin-top: 40px;
  @media screen and (max-width: 1600px) {
    width: 1050px;
  }
`;

const ImgChange = styled.div`
  background-image: url(${header});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  width: calc(100vw - 5px);
  height: calc(50vh - 4vw);
`;
