import React, { useState } from 'react';
import {
  Layout,
  Menu,
  Row,
  Col
} from 'antd';
import './assets/index.css';
import logo from './assets/chintLogo.svg';
import SubMenu from 'antd/lib/menu/SubMenu';
import QRcode from './assets/QRcode.jpg';
import serviceCode from './assets/serviceCode.jpg';
import tiktok from './assets/tiktok.jpg';
import { platUrl } from '../urlConfig.js';
import importAllJs from '../common/importAll';

// 批量导入JS
const importJS = importAllJs(require.context('../Views', true, /\.js$/));
const { HomePage, MultiPark, /*Document,*/ BizHome, DataHome, Distributed, PVmodule, PowerOM } = importJS;

function Layouts() {
  const { Header, Content, Footer } = Layout;

  const [menuTab, setMenuTab] = useState("home");
  const [showMenu, setShowMenu] = useState(true);

  // 菜单导航栏
  /*菜单内容 */
  const MenuContent = [
    {title: "首页", key: "home"},
    {title: "平台服务", key:"sercive", subtitle: [
      {title: "正泰智能配电", children:[{name: "了解详情", key: "MultiPark"}]},
      {title: "业务中台", children:[{name: "了解详情", key: "biz"}]},
      {title: "数据中台", children:[{name: "了解详情", key: "data"}]},
    ]},
    {title: "解决方案", key: "solution", subtitle: [
      {title: "多能互补园区", children:[{name: "了解详情", key: "MultiPark"}]},
      {title: "分布式光伏", children:[{name: "了解详情", key: "distributed"}]},
      {title: "光伏组件制造", children:[{name: "了解详情", key: "PVmodule"}]},
      {title: "电力运维", children:[{name: "了解详情", key: "PowerOM"}]},
    ]},
    //{title: "文档中心", key: "document"},
    // {title: "正泰云社区", key: "bbs"}
  ]
  /*菜单内容Wrap */
  const MenuBar = () => {
    return MenuContent.map((item) => {
      if(item.subtitle) {
        return <SubMenu key={item.key} title={item.title}>
          {Subs(item.subtitle)}
        </SubMenu>
      } else {
        return <Menu.Item key={item.key}>
          {item.title}
        </Menu.Item>
      }
    })
  }
  const Subs = (arr) => {
    return arr.map((e) => {
      return <Menu.ItemGroup className="itemGroup" title={e.title} key={e.title}>
        {e.children.map((d) => {
          return <Menu.Item key={d.key}>{d.name}</Menu.Item>
        })}
      </Menu.ItemGroup>
    })
  }

  // 菜单导航切换
  const MenuChange = (e) => {
    if(e.key === 'bbs') {
      window.open('https://bbs.chintcloud.net','_blank','noreferrer')
    } else if(e.key === 'cloudenter') {
      window.open(platUrl.cloud);
      setMenuTab(e.key);
    } else if(e.key === 'bizenter') {
      window.open(platUrl.bmp);
      setMenuTab(e.key);
    } else if(e.key === 'dataenter') {
      window.open(platUrl.dmp);
      setMenuTab(e.key);
    } else {
      setMenuTab(e.key);
    }
    document.querySelector(".App").scrollIntoView({behavior: "smooth"}); // 自动滑动到顶部
  }
  // 菜单选择后内容组件切换
  const LayoutContent = () => {
    switch(menuTab){
      case "home":
        return <HomePage showMenu={setShowMenu} />
      case "cloudenter":
        return <HomePage showMenu={setShowMenu} />;
      case "biz":
        return <BizHome />
      case "bizenter":
        return <BizHome />;
      case "data":
        return <DataHome />
      case "dataenter":
        return <DataHome />;
      case "MultiPark":
        return <MultiPark />
      case "distributed":
        return <Distributed />
      case "PVmodule":
        return <PVmodule />
      case "PowerOM":
        return <PowerOM />
      // case "document":
      //   return <Document />
      default:
        return <HomePage showMenu={setShowMenu} />
    }
  }

  // 底部链接
  /*链接内容，menuTab为站内页面跳转，href为外部网站链接 */
  const fooItems = [
    {title: "解决方案", contents: [
      {item: "多能互补园区", menuTab: "MultiPark"},
      {item: "分布式光伏", menuTab: "distributed"},
      {item: "光伏组件制造", menuTab: "PVmodule"},
      {item: "电力运维", menuTab: "PowerOM"}
    ]},
    {title: "服务与支持",contents: [
      {item: "联系我们", href: "contact"},
      {item: "下载中心", href: "download"},
      {item: "业务讲堂", href: "lecture"},
      {item: "数字化工具", href: "tool"},
    ]},
    {title: "关于我们",contents: [
      {item: "企业介绍", href: "introduce"},
      {item: "新闻中心", href: "news"},
      {item: "展厅与活动", href: "exhibition"},
      {item: "人才招聘", href: "recruitment"},
    ]}
  ]
  /*内容Wrap */
  const FooCards = () => {
    return fooItems.map((e) => <Col key={e.title}>
      <div className="infoCard">
        <h4>{e.title}</h4>
        {FooCard(e.contents)}
      </div>
    </Col>)
  }
  const FooCard = (arr) => {
    return arr.map((e) => <p className="cardsItem" key={e.item} onClick={() =>fooLink(e)}>{e.item}</p>)
  }
  /*链接或者跳转处理 */
  const fooLink = (arr) => {
    if(arr.href) {
      window.open("https://www.chint.com/" + arr.href);
    } else if(arr.menuTab) {
      setMenuTab(arr.menuTab);
      setShowMenu(true);
      document.querySelector(".App").scrollIntoView({behavior: "smooth"});
    }
  }

  return (
    <div className="App">
      <Layout style={{ background: "#FFFFFF" }}>
        <Header className="layoutHeader" style={{visibility: showMenu ? 'visible' : 'hidden', height: showMenu? "90px" : 0}}>
          <div className="layoutNav" style={{visibility: showMenu ? 'visible' : 'hidden', height: showMenu? "90px" : 0}}>
            <div className="menuWrap">
              <div className="logo" >
                <img
                  src={logo}
                  alt="正泰云平台"
                  style={{marginTop: showMenu? "36px" : "0"}}
                />
              </div>
              <Menu
                mode="horizontal"
                className="layoutMenu"
                onClick={MenuChange}
              >
                {MenuBar()}
              </Menu>
            </div>
          </div>
        </Header>
        <Content style={{paddingTop: "90px"}}>
          <LayoutContent />
        </Content>
        <Footer style={{padding: 0, minWidth: "1280px"}}>
          <div className="information">
            <Row gutter={[16,16]}>
              {FooCards()}
              <Col>
                <div className="QRcode">
                  <div>
                    <img src={QRcode} alt="官方订阅号"></img>
                    <p>官方订阅号</p>
                  </div>
                  <div>
                    <img src={serviceCode} alt="官方服务号"></img>
                    <p>官方服务号</p>
                  </div>
                  <div>
                    <img src={tiktok} alt="官方抖音号"></img>
                    <p>官方抖音号</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footer">Copyright © 2019-{new Date().getFullYear()} All Rights Reserved. 正泰集团 版权所有 版本号V2.1.0 浙ICP备11016013号</div>
        </Footer>
      </Layout>
    </div>
  )
};

export default Layouts;
