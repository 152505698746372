
import { Row, Col } from 'antd';
import './assets/document/index.css';
import {importAllPngs} from '../common/importAll';

export default function Document() {

  // 批量导入png图片
  const importPng = importAllPngs(require.context("./assets/document", true, /^\.\/.*\.png$/));
  const {APIdev, download, func, netSafe, protocol, test} = importPng;

  const Goto = (prop) => {
    const {menu, href} = prop;
    if(menu) {
      window.open("https://iot-cloud.chintcloud.net/documentIndex.html?menu=" + menu);
    } else if(href) {
      window.open(href);
    }
  }

  const docCards = [
    // {title: "平台介绍", item: ["发展战略", "平台架构", "快速入门"], menu:"introduce"},
    {title: "通讯协议", img: protocol, item: ["MQTT", "LWM2N", "OPC UA"], menu:"commun"},
    {title: "网络安全", img: netSafe, item: ["SSL/TSL", "身份认证&权限认证"], menu:"security"},
    {title: "测试用例/机理模型", img: test, item: ["工业大数据存储分析测试用例", "工业数据智能测试用例", "边缘计算分析测试用例","工控系统测试用例","5G网络切片应用测试用例","区块链技术应用测试用例","分布式新能源数字孪生测试用例"], href:"http://10.10.101.204:9002/testcase/#/index"},
    {title: "功能模块", img: func, item: ["设备接入", "设备管理", "数据可视化", "边缘计算", "数据湖"], menu:"function"},
    {title: "API开发指南", img: APIdev, item: ["基础服务API"], menu:"api"},
    {title: "下载中心", img: download, item: ["云平台根证下载", "SDK下载", "文档下载"], menu:"download"}
  ]

  const DocCards = () => {
    return docCards.map((e) =>
      <Col key={e.title}>
        <div className="card">
          <div>
            <h3 style={{backgroundImage: "url(" + e.img+ ")" }}>{e.title}</h3>
            <div className="item" onClick={() => Goto({menu: e.menu, href: e.href})}>
              {CardItem(e.item)}
            </div>
          </div>
        </div>
      </Col>
    )
  }
  const CardItem = (arr) => {
    return arr.map((e) => 
      <p key={e}>{e}</p>
    )
  }

  return (
    <div className="docCenter">
      <div className="topWrap">
        <div className="contentWrap">
          <h2>文档中心</h2>
          <p>在这里，您可以深入了解正泰云平台强大的开发能力和帮助文档</p>
        </div>
      </div>
      <div className="docCardWrap">
        <Row gutter={[16,16]}>
          {DocCards()}
        </Row>
      </div>
    </div>
  )
}
