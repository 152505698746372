import React, { useState } from 'react';
import { Button, Row, Col, Menu, Carousel } from 'antd';
import './assets/homePage/homePage.css';
// import { platUrl } from '../urlConfig.js';
import { importAllSvgs } from '../common/importAll';

function HomePage(props){
  const { showMenu } = props;

  const importSVG = importAllSvgs(require.context('./assets/homePage/', true, /\.svg$/));
  const {adjustable, safety, scalability, interactivity, homePic, architecture, security, system, deployLocal, deployPublic, deployPrivate} = importSVG;

  const cloudIntro = "为顺应现代能源，智能制造和数字化技术融合发展大趋势，正泰集团自主研发云平台。正泰云平台是智慧科技与数据应用的载体，连接企业内部制造与经营管理数据，把各种IT和OT数据融合，形成统一数据资产和统一服务能力，为各种应用场景复用，逐渐沉淀成为服务多个垂直行业的云平台，实现企业对内与对外的数字化应用与服务。为企业治理效率的提升、业务流程与组织架构的升级、运营与决策的精细化赋能。"
  const posBar = [
    {index: 1, name: "正泰云架构", hidden: false},
    {index: 2, name: "正泰云PaaS层", hidden: false},
    {index: 3, name: "工业互联网平台", hidden: true},
    {index: 4, name: "安全防护体系", hidden: false},
    {index: 5, name: "正泰云特性", hidden: false},
    {index: 6, name: "正泰云部署", hidden: false},
    {index: 7, name: "开放生态系统", hidden: false},
  ]
  const cloudServices = [
    {title:"数据中台", intro: "通过建立统一的数据标准、数据资产、数据开发、数据融合、数据服务、数据变现、数据运维，支持海量数据的离线加工分析，让数据从采集到展现、从分析到驱动应用得以工具化，一站式解决，真正实现数据业务化、业务数据化。"},
    {title:"业务中台", intro: "提供可视化的数据，组件，业务，领域的建模，配置式接口设计，定制业务流程，便捷的大屏和报表设计，灵活的布局设计器等能力，零代码搭建灵活易用，满足多种业务需求，高效构建企业应用。把业务需求快速变成现实，让人人都是开发人员，为企业贡献多元价值。"},
    {title:"可视化工具", intro: "正泰云可视化工具服务是一站式数据分析，展示，应用开发可视化平台，旨在帮助企业用户通过可视化图表展示海量数据，快速打造出专业大屏数据展示，数据服务接口，搭建个性化企业应用。精心预设多种行业模板，极致展示数据魅力。可视化工具服务采用拖拽式自由布局，无需编码，全图形化编辑，快速可视化制作。\n正泰云组态支持多种工业行业测点数据配置，采用拖拽式自由布局，快速绘制丰富的组态图，提供丰富的组件和行业组件，支持数据实时同步更新。"},
    {title:"移动应用平台", intro: "正泰云移动端开发平台以模块化，微服务架构为建设思路，支持不同开发语言进行二次开发的移动开发框架，满足用户WIN/Linux部署，为用户提供大量的即插即用的业务服务组件，实现APP、微信服务号、订阅号、企业号、小程序、PC及微站四站合一的移动开发。移动端开发平台为开发人员提供丰富的移动框架API及SDK，从接入规范，代码示例、功能开发、代码发布的全过程一整套解决方案。"},
    {title:"SaaS应用开发", intro: "支持私有部署，简易安装和运维升级，放心运转业务数据，给有开发能力的客户提供一个实现完全定制化的开发框架，是对零代码开发的一个补充。无缝对接云平台。"},
    {title:"人工智能", intro: "正泰云人工智能，基于机器学习提供从算法开发、模型训练、模型评估、在线/离线预测的一站式服务。支持回归、分类、聚类等多种算法，对采集到云端的数据进行清洗，整合，分析，建立知识图谱和企业知识库。"},
  ]
  const cloudStruct = [
    {title: "数据采集层", content:"支持复杂网络环境和协议、多样异构数据源之间的数据采集上云。符合正泰物联网TaiLink协议的设备，即可无缝接入正泰云，并支持非标协议，实现互联互通。"},
    {title: "PaaS层", content:"作为正泰云的核心，包括数据中台，业务中台，云组态，人工智能，提供丰富的API及微服务开发环境。"},
    {title: "SaaS层", content:"提供丰富的能源物联网应用和工业物联网应用，服务行业。"},
    {title: "安全防护体系", content:"提供设备层、网络层、云端等多个层面的安全防护和管理机制。"}
  ]
  const cloudSecurity = "安全管理模块提供了SSL/TLS标准、身份认证&权限策略、通信安全&传输加密、操作审计、数据脱敏、实时监控等多个层面的有效安全管理机制，保障平台与数据的安全。"
  const feaCard = [
    {img: safety, alt: "安全性", title: "安全性", item: ["数据库安全", "设备连接安全", "多数据中心", "数据异地备份"]},
    {img: adjustable, alt: "可扩展性", title: "可扩展性", item: ["支持微服务", "支持容器化", "支持模块化", "支持组态化"]},
    {img: interactivity, alt: "数据交互性", title: "数据交互性", item: ["支持多租户", "支持数据隔离", "支持存储隔离", "支持数据聚合分析"]},
    {img: scalability, alt: "可伸缩性", title: "可伸缩性", item: ["运算处理能力动态扩容", "数据存储能力动态扩容"]}
  ]
  const cloudDeploy = [
    {title: "本地化部署", content:"可利用企业现有的计算资源,企业直接负责基础架构的配置、管理和安全,私密数据的安全性更有保障,计算资源完全可控和灵活调配。", img: deployLocal},
    {title: "公有云部署", content:"可以提供基于共享资源服务的公有云部署，降低企业IT运营和维护成本，提供灵活扩展和部署的能力，具有弹性伸缩、成本低、无限扩展的优点。", img: deployPublic},
    {title: "私有云部署", content:"为企业或组织提供专用的云环境，提供对数据的安全性和服务质量的最有效控制，并能根据客户需要进行扩展和获得我们更多的定制化服务。", img: deployPrivate}
  ]
  const cloudSystem = "正泰能源物联网构筑了区域智慧能源综合运营管理生态圈，为政府、工商业及终端用户提供光伏发电、多能互补、储能系统、智慧热网、智慧微网、能效楼宇等一揽子能源解决方案， 让能源更安全、绿色、便捷、高效；正泰工业物联网构建基于海量数据采集、汇聚、分析的服务体系，支撑制造资源泛在连接，为智能制造赋能，提供工业控制自动化解决方案等，助力传统制造业数字化转型。"

  const slider = React.createRef()

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  const [introSelect, setIntroSelect] = useState(cloudServices[0].title);
  const [selectKey, setSelectKey] = useState("item1");

  // 云特性
  const FeaCards = () => {
    return feaCard.map((e) =>
      <Col key={e.title}>
        <div className="card">
          <img src={e.img} alt={e.alt} />
          <div>
            <h3>{e.title}</h3>
            {CardItem(e.item)}
          </div>
        </div>
      </Col>
    )
  }
  const CardItem = (arr) => {
    return arr.map((e) => 
      <p key={e}>{e}</p>
    )
  }

  // PaaS层交互
  const ClickNav = (e) => {
    setIntroSelect(e.key);
  }

  // 立即使用跳转
  const handleClick = () => {
    // window.open(platUrl.cloud)
  }

  // 定位栏跳转
  const toTegional = (e) => {
    // showMenu(false);
    const id = "#" + e.key;
    // document.querySelector(id).scrollIntoView({behavior: "smooth", block: "center"}, true);
    const top = document.querySelector(id).offsetTop
    window.scrollTo({ 
      top: top - 100, 
      behavior: "smooth" 
  });
    setSelectKey(e.key);
  }

  // 菜单栏的显示隐藏，定位栏随之变化
  const scrollFunc = (e=window.event) => {
    const scrollHeight = document.body.scrollTop || document.documentElement.scrollTop;
    const clientHeight = document.body.clientHeight;
    if(document.querySelector('.page-tab-nav')){
      let menu = document.querySelector('.page-tab-nav');
      if(scrollHeight < clientHeight - 190) {
        menu.style.position = 'static';
        menu.style.width = "100%";
        menu.style.zIndex = 1;
        showMenu(true);
        document.querySelector('#menu .ant-menu').style.borderBottom="none"
        document.querySelector('#menu').style.borderBottom="none"
      } else {
        menu.style.position = 'fixed';
        menu.style.width = "100%";
        menu.style.zIndex = 1;
        document.querySelector('#menu .ant-menu').style.borderBottom="5px solid #28C1FF"
        document.querySelector('#menu').style.borderBottom="5px solid #28C1FF"
        if(e.wheelDelta > 0 || e.detail < 0) {
          showMenu(true);
          menu.style.top="90px";
        } else {
          showMenu(false);
          menu.style.top="0px";
        }
      }
    }
    if(scrollHeight >= 0 && scrollHeight <= clientHeight * 1.4) {
      setSelectKey("item1");
    } else if(scrollHeight > clientHeight * 1.4 && scrollHeight <= clientHeight * 2.4) {
      setSelectKey("item2");
    } else if(scrollHeight > clientHeight * 2.4 && scrollHeight <= clientHeight * 3.5) {
      setSelectKey("item4");
    } else if(scrollHeight > clientHeight * 3.5 && scrollHeight <= clientHeight * 4.6) {
      setSelectKey("item5");
    } else if(scrollHeight > clientHeight * 4.6 && scrollHeight <= clientHeight * 5.6) {
      setSelectKey("item6");
    } else if(scrollHeight > clientHeight * 5.6) {
      setSelectKey("item7");
    }
  }
  // 监听滚轮事件
  window.onmousewheel = document.onmousewheel = scrollFunc;
  /*火狐 */
  if(document.addEventListener){ 
  document.addEventListener('DOMMouseScroll',scrollFunc,false); 
  }

  // 云架构高亮样式
  const ClickTypeChange = (e) => {
    cloudStruct.map((item) => {
      if(item.title === e.key){
        return document.querySelector("." + e.key).style.background="rgba(255, 255, 255, 0.25)";
      } else {
        return document.querySelector("." + item.title).style.background="transparent";
      }
    })
  }
  // 云部署交互
  const ClickDeployChange = (e) => {
    GoTo(e);
    cloudDeploy.map((item) => {
      if(item.title === e.key){
        return document.querySelector("." + e.key).style.background="rgba(255, 255, 255, 0.25)";
      } else {
        return document.querySelector("." + item.title).style.background="transparent";
      }
    })
  }

  const GoTo = (e) => {
    if(e.key === "本地化部署") {
      slider.current.goTo(0, false);
    } else if(e.key === "公有云部署") {
      slider.current.goTo(1, false);
    } else if(e.key === "私有云部署") {
      slider.current.goTo(2, false);
    }
  }

  return(
    <div className="home">
      <div className="homeIntro">
        <div className="introWrap">
          <div className="contentWrap">
            <h1>正泰云</h1>
            <p>{cloudIntro}</p>
            <Button onClick={handleClick} >立即使用</Button>
          </div>
          <img src={homePic} alt="正泰云" />
        </div>
      </div>
        <div className="page-tab-nav-wrap">
          <div className="page-tab-nav">
            <div id="menu">
              <Menu
                onClick={toTegional}
                defaultSelectedKeys="item1"
                selectedKeys={selectKey}
              >
                {posBar.map((item) => 
                  !item.hidden && <Menu.Item key={"item" + item.index}>{item.name}</Menu.Item>
                )}
              </Menu>
            </div>
          </div>
        </div>
      <div id="item1" className="homeStruct">
        <div className="structWrap">
          <div className="contentWrap">
            <h2>正泰云架构</h2>
            <p style={{marginBottom: "20px", width: "650px"}}>为企业治理效率的提升、业务流程与组织架构的升级、运营与决策的精细化赋能。</p>
            <Menu
              className="serviceMenu"
              mode="horizontal"
              onClick={ClickTypeChange}
              defaultSelectedKeys={[cloudStruct[0].title]}
              >
                {cloudStruct.map(item => (
                  <Menu.Item key={item.title}>{item.title}</Menu.Item>
                ))}
            </Menu>
            {cloudStruct.map((e) => 
              <div
                key={e.title}
                className={e.title}
                style={{
                  background: e.title === "数据采集层" ? "rgba(255, 255, 255, 0.25)" : "transparent",
                  padding: "10px 20px", fontSize: "20px",
                  transition: "background 0.6s",
                  textAlign: "justify",
                  display: "inline-flex",
                  width: "667px",
                }}
              >
                <p style={{whiteSpace: "nowrap"}}>{e.title} :&nbsp;</p>
                <p>{e.content}</p>
              </div>
            )}
          </div>
          <img src={architecture} alt="正泰云架构" />
        </div>
      </div>
      <div id="item2" className="homeService">
        <div className="serviceWrapped">
          <h2>正泰云PaaS层</h2>
          <div className="menuCard">
            <Menu
              className="serviceMenu"
              mode="horizontal"
              onClick={ClickNav}
              defaultSelectedKeys={[cloudServices[0].title]}
              >
                {cloudServices.map(item => (
                  <Menu.Item key={item.title}>{item.title}</Menu.Item>
                ))}
            </Menu>
            <div className="serviceIntro">
              {cloudServices && cloudServices.map((e) => {
                if(e.title === introSelect) {
                  return (
                    <div className="inner" key={introSelect}>{e.intro}</div>
                  )
                } else {
                  return <div className="inner" key={e.title}></div>
                }
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <div id="item3" className="homePlatform">
        <div className="platformWrap">
          <h2 style={{color: "#FFFFFF"}}>正泰工业互联网</h2>
          <p>作为正泰云的基础和核心，正泰工业互联网平台是一个用于数据收集，处理，可视化和设备管理的IoT平台。它通过行业标准物联网协议（MQTT，Modbus TCP、OPC UA和HTTP）实现设备连接，并支持云和本地部署。正泰工业互联网平台结合了可扩展性，容错性和性能，因此您永远不会丢失数据。</p>
        </div>
      </div> */}
      <div id="item4" className="homeSecurity">
        <div className="contentWrap">
          <div className="securityWrap">
            <h2>正泰云安全防护体系</h2>
            <p>{cloudSecurity}</p>
          </div>
          <img src={security} alt="正泰云安全防护体系" style={{height: "300px"}}></img>
        </div>
      </div>
      <div id="item5" className="homeFeature">
        <div className="contentWrap">
          <div className="title">
            <h2>正泰云特性</h2>
            <p>正泰云平台具备各类强壮而有效的特性，能够适应各类复杂的业务场景。</p>
          </div>
          <div className="cardWrap">
            <Row gutter={[16,16]}>
              {FeaCards()}
            </Row>
          </div>
        </div>
      </div>
      <div id="item6" className="homeDeploy">
        <div className="deployWrap">
          <div className="contentWrap">
            <h2>正泰云部署方式</h2>
            <p style={{marginBottom: "20px", width: "642px"}}>正泰云平台具备各类强壮而有效的特性，能够适应各类复杂的业务场景。</p>
            <Menu
              className="serviceMenu"
              mode="horizontal"
              onClick={ClickDeployChange}
              defaultSelectedKeys={[cloudDeploy[0].title]}
              >
                {cloudDeploy.map(item => (
                  <Menu.Item key={item.title}>{item.title}</Menu.Item>
                ))}
            </Menu>
            {cloudDeploy.map((e) => 
              <div
                key={e.title}
                className={e.title}
                style={{
                  background: e.title === "本地化部署" ? "rgba(255, 255, 255, 0.25)" : "transparent",
                  padding: "10px 20px", fontSize: "20px",
                  transition: "background 0.6s",
                  textAlign: "justify",
                  display: "inline-flex",
                  width: "642px",
                }}
              >
                <p style={{whiteSpace: "nowrap"}}>{e.title} :&nbsp;</p>
                <p>{e.content}</p>
              </div>
            )}
          </div>
          {/* <img src={architecture} alt="正泰云架构" /> */}
          <div className="deployImg" style={{width: "571px", height: "500px"}}>
            <Carousel
              ref={slider}
              {...settings}>
              <div>
                <img src={deployLocal} alt="本地化部署" style={{width: "571px", height: "500px"}}/>
              </div>
              <div>
              <img src={deployPublic} alt="公有化部署" style={{width: "571px", height: "500px"}}/>
              </div>
              <div>
                <img src={deployPrivate} alt="私有化部署" style={{width: "571px", height: "500px"}}/>
              </div>
            </Carousel >
          </div>
        </div>
      </div>
      <div id="item7" className="homeSystem">
        <div className="systemWrap">
          <div>
            <h2>正泰云开放生态系统</h2>
            <p>{cloudSystem}</p>
          </div>
          <img src={system} alt="正泰云开放生态系统" />
        </div>
      </div>
    </div>
  )
}

export default HomePage;
