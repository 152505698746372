const importAllJs = context => {
  const map = {}

  for (const key of context.keys()) {
    const keyArr = key.split('/')
    keyArr.shift() // 移除.
    map[keyArr.join('.').replace(/\.js$/g, '').replace(/( |^)[a-z]/g, (L) => L.toUpperCase())] = context(key).default
  }

  return map
}

export default importAllJs

export const importAllSvgs = context => {
  const map = {}

  for (const key of context.keys()) {
    const keyArr = key.split('/')
    keyArr.shift() // 移除.
    map[keyArr.join('.').replace(/\.svg$/g, '')] = context(key).default
  }

  return map
}

export const importAllPngs = context => {
  const map = {}

  for (const key of context.keys()) {
    const keyArr = key.split('/')
    keyArr.shift() // 移除.
    map[keyArr.join('.').replace(/\.png$/g, '')] = context(key).default
  }

  return map
}
