import './assets/powerOM/powerOM.css';
import { Button } from 'antd';

export default function PowerOM() {
  const intro = require('./assets/powerOM/powerOMIntro.svg').default;
  const img1 = require('./assets/powerOM/Image1.svg').default;

  const goToWebsite = () => {
    window.open("https://qusfljwd.cloudpreview.chintcloud.net")
  }

  return (
  <>
    <div className="PowerOMHeader">
      <div className="headerWrap">
        <div className="headerContent">
          <h1>电力运维</h1>
          <p>正泰电气服务底蕴与正泰云平台技术、人工智能技术、通信技术等结合形成优势互补，打造一套业务落地、技术领先的完整解决方案。</p>
          <p>在输配用电领域减少运维的压力，使用户能得到更及时、更方便、更经济的运维服务。形成资源整合高效合作，多方共赢。</p>
        </div>
        <Button onClick={goToWebsite}>进入 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; →</Button>
      </div>
    </div>
    <div className="PowerOMIntro">
      <div className="Wrap">
        <img src={intro} alt="方案介绍"></img>
        <div className="introWrap">
          <h2 style={{display: "inline-block"}}>方案介绍</h2>
          <p>变电站作为电力供应系统中的重要组成元素，担负着电能的降低与输送的重任。变配电站（室）数量相当庞大，供电对象错综复杂，且与生产工艺关联度非常高。</p>
          <p>故障状态下，单凭常规“人力”的巡检方式，难度是非常之大。但靠人工24小时值班的传统的变配电站（室）管理模式已无法适应迅速发展的输配电市场需求。地域上分散的用电终端、站、所、室需要组成一个集中监管群，对电力设备的运行状态、设备管理进行远程实时集中监控，实现对变配电设施无人值守。</p>
        </div>
      </div>
    </div>
    <div className="PowerOMFeature">
      <div className="Wrap">
        <div className="featureWrap">
          <h2 style={{display: "inline-block"}}>方案特点</h2>
          <p>利用大数据进行状态跟踪与统计分析，充分利用“云、大、物、移、智”先进技术，及时推送预警或报警信息，实现运行状态全面监测、健康状态综合评估、异常主动告警、趋势自动跟踪、报表主动推送的全寿命周期资产管理是电力市场的迫切需求。</p>
        </div>
        <img src={img1} alt="方案特点"></img>
      </div>
    </div>
  </>)
}
