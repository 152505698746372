import './assets/distributed/distributed.css';
import { Button } from 'antd';
import intro from './assets/distributed/intro.png';
import feature from './assets/distributed/feature.png';

export default function Distributed() {

  const goToWebsite = () => {
    window.open("https://smartportal.astronergy.com/")
  }

  return (
  <>
    <div className="distributedHeader">
      <div className="headerWrap">
        <div className="headerContent">
          <h1>分布式光伏</h1>
          <p>正泰新能源在正泰“一云两网”的战略推动下，通过探索沙光、农光、渔光、牧光等多种类型的“光伏 +”项目，提出“合筑锦绣光伏”，把经济、社会和环境三效益统一融合，构建协同共赢的发展格局。</p>
        </div>
        <Button onClick={goToWebsite}>进入 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; →</Button>
      </div>
    </div>
    <div className="distributedIntro">
      <div className="Wrap">
        <img src={intro} alt="方案介绍"></img>
        <div className="introWrap">
          <h2 style={{display: "inline-block"}}>方案介绍</h2>
          <p>太阳能光伏作为分布式新能源的一个重要领域，近年来在国内外得到快速发展。我国光伏电站的装机容量已位居全球第一，国内部分光伏组件的生产厂家有一定的数字集成制造能力，光伏电站运行维护中的自动化水平也在不断提高。利用工业互联网平台对分布式新能源进行赋能，提供完整的的从生产制造、设计建设、运行维护端到端的数字化解决方案，可以提高分布式新能源行业运营效益，保障电站与电网安全，为社会提供高质量、高可靠性的清洁绿色能源。</p>
        </div>
      </div>
    </div>
    <div className="distributedFeature">
      <div className="Wrap">
        <div className="featureWrap">
          <h2 style={{display: "inline-block"}}>方案特点</h2>
          <p>光伏电站运行中由于光伏阵列的工作环境恶劣，光伏阵列表现出的故障模式具有多样化的特点，光伏电站故障频发导致光伏电站的发电出力降低。利用工业数据智能技术可以对光伏阵列的状态进行诊断，从而做到光伏电站的预测性维护，降低电站的故障率和运维成本。</p>
        </div>
        <img src={feature} alt="方案特点" style={{width: "530px"}}></img>
      </div>
    </div>
  </>)
}
