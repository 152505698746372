import './assets/PVmodule/pvmodule.css';
import { Button } from 'antd';
import feature from './assets/PVmodule/feature.png';

export default function PVmodule() {
  const img1 = require('./assets/PVmodule/Image1.svg').default;

  const goToWebsite = () => {
    window.open("http://10.10.14.93/chint-aps/index")
  }

  return (
  <>
    <div className="PVmoduleHeader">
      <div className="headerWrap">
        <div className="headerContent">
          <h2 style={{display: "inline-block"}}>光伏组件制造</h2>
          <p>正泰新能源着力打造智能工厂，布局杭州、海宁、泰国等地，早在2016年正泰智能工厂就获得工信部授予的“中德智能制造示范基地”、2020年公司又获得工信部授予的第一批“智能光伏试点示范企业”，达到国内智能制造创新发展的领先水平。至2021年底，正泰的光伏组件产能将达到12GW，产品拥有全球主流市场认证体系，远销全球50多个国家和地区，深度融入到全球新能源产业链中。</p>
        </div>
        <Button onClick={goToWebsite}>进入 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; →</Button>
      </div>
    </div>
    <div className="PVmoduleIntro">
      <div className="Wrap">
        <div className="introWrap">
          <h2 style={{display: "inline-block"}}>方案介绍</h2>
          <p>正泰新能源对原电池、组件生产线进行自动化改造，打造成为国内行业领先的光伏电池、组件生产标杆工厂。引进国内先进的全自动化生产设备，在组装工艺中集成使用大型重载机器人，智能生产线涵盖智能装备（自动化设备）、工业互联网（RFID 、以太网、传感器等）、工业软件（ MES ）以及设备自动化集成与生产线集成。其中工业互联网就是智能工厂的神经。</p>
          <p>通过各种信息采集和感知技术，将生产中的信息转为数字信号，通过无线传输、总线技术、工业以太网等技术将信号传递给计算机系统或者展示给生产人员，经过数据分析处理后，实现更加精确和更高效率的工业生产操作。使正泰新能源建成为国内光伏行业领先的太阳能电池、组件智能制造工厂。</p>
        </div>
      </div>
    </div>
    <div className="PVmoduleFeature">
      <div className="Wrap">
        <div className="featureWrap">
          <h2 style={{display: "inline-block"}}>方案特点</h2>
          <p>工业互联网作为新一代信息技术与现代工业融合发展催生的新事物，是工业经济数字化、网络化、智能化的重要基础设施。基于正泰云的光伏组件制造应用将推动面向分布式新能源系统的核心关键部件生产制造过程的数字化、网络化、智能化。</p>
        </div>
        <img src={feature} alt="方案特点" style={{width: "630px"}}></img>
      </div>
    </div>
    <div className="PVimg">
      <img src={img1} alt="正泰新能源智慧能源管理平台" style={{width: "1280px", margin: "auto"}}></img>
    </div>
  </>)
}
