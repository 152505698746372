import 'antd/dist/antd.css';
import Layouts from './Layout/index.js';

function App() {
  return (
    <Layouts/>
  );
}

export default App;
